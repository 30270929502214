import React from "react";
import { useEffect } from "react";

import "./style.css";

const Troll = () => {
    useEffect(() => {
        document.title = "Rocket League - Article officiel";
    }, []);
    return (
        <div className="background-container">
            <div className="troll-container">
                <h1 className="troll-title">Site officiel spécialisé sur Rocket League</h1>
                <p className="troll-text">D'après des recherches très poussées, <b>Louis</b> est beaucoup plus fort que <b>Timothée</b> sur Rocket League.</p>
                <p className="troll-text">Jérôme, pour le coup, est légèrement plus fort que Timothée, mais bien loin du niveau du Crack Lucho !!</p>
            </div>
        </div>
    );
}

export default Troll;
