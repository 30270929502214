import React from 'react';
import '../../EnComponents/Welcome/style.css';

function Welcome() {
    const handleDownloadCV = () => {
        const cvUrl = 'CV_LB_2025.pdf';
        const link = document.createElement('a');
        link.href = cvUrl;
        link.download = 'CV_Louis_BERTRAND_2025.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="welcome" role="region" aria-label="Welcome Section">
            <h1>Bienvenue</h1>
            <p>Étudiant en informatique motivé, passionné par les technologies émergentes et le développement logiciel.</p> 
            <p>Actuellement en cycle ingénieur à l'EPITA tout en finalisant une licence en informatique à l'UPEC, je cherche à acquérir de l'expérience dans le domaine des systèmes informatiques, du développement web, ou de l'intelligence artificielle.</p>
            
            <button onClick={handleDownloadCV} className="download-btn">Télécharger mon CV</button>
        </div>
    );
}

export default Welcome;
